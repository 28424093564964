import React, { useRef } from "react";
import { IS_SERVER } from "@/helpers/CompileHelper";
import useEventListener from "@/hooks/EventListener";

const InfoBubble = (props) => {
    const bubbleRef = useRef();

    const onClick = (e) => {
        e.stopPropagation();
    }

    const blur = (e) => {
        document.activeElement?.blur?.();
    };

    useEventListener("resize", () => {
        if (IS_SERVER) return;

        if (window.innerWidth <= 600) {
            let rect = bubbleRef.current.getBoundingClientRect();
            bubbleRef.current.children[0].style.left = `-${ rect.x - 12 }px`;
        }
    })

    const onEnter = (e) => {
        let rect = e.target.getBoundingClientRect();
        if (e.target.children?.length > 0) {
            if (window.innerWidth <= 600) {
                e.target.children[0].style.left = `-${ rect.x - 12 }px`;
            } else {
                e.target.children[0].style.left = null;
            }
        }
    }

    return (
        <div className={ `info__container ${props?.className ?? ""}` } onClick={onClick} tabIndex="1">
            <div className={ "info__bubble " + (props?.bubble?.className ?? "") } onPointerEnter={onEnter} ref={bubbleRef}>
                <div className="info__body background--black" onClick={blur}>
                    {props?.children}
                </div>
            </div>
        </div>
    );
};

export default InfoBubble;
