import React, { useLayoutEffect, useState } from "react";
import Link from "next/link";
import Footer from "@/components/Footer";
import InfoBubble from "@/components/InfoBubble";
import { IS_SERVER } from "@/helpers/CompileHelper";
import InfoPage from "@/components/InfoPage";
import ContactPage from "@/components/contact-page";

export { getStaticProps } from "@/components/Boilerplate/props/case-categories";

const Root = (props) => {
    const [subdomain, setSubdomain] = useState("");

    useLayoutEffect(() => {
        if (IS_SERVER) return;
        setSubdomain(window.location.hostname.split(".")[0].toLowerCase());
    }, []);

    if (subdomain === "dokumenter") {
        return (
            <main>
                <section className="section--cover">
                    <div className="container container--valign center mb-8">
                        <div className="row justify-content-center mb-4">
                            <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                                <h1 className="h2m center">
                                    Siden er ikke ferdig
                                </h1>
                            </div>
                        </div>

                        <div className="row justify-content-center mb-8">
                            <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                                <Link href="https://mekle.no/">
                                    <div className={ "button background--black" }>
                                        Returner til hovedsiden
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer/>
            </main>
        );
    }

    if (subdomain === "info") {
        return (
            <main>
                <InfoPage categories={props?.categories}/>
                <Footer/>
            </main>
        );
    }

    if (subdomain === "kontakt") {
        return (
            <main>
                <ContactPage/>
                <Footer/>
            </main>
        );
    }

    return (
        <main>
            <section className="section--cut-top">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-8 center">
                            <h1>
                                Meld sak
                            </h1>
                            <h4>
                                Registrer din sak helt uforpliktende. Vi kontakter din motpart og det påløper ingen kostnad dersom motpart ikke ønsker å delta.
                            </h4>
                        </div>
                    </div>
                </div>
            </section>
            <section className="background--light-grey">
                <div className="container">
                    <div className="row justify-content-center my-4">
                        <div className="col-12 col-lg-10 center">
                            <div className="input__group">
                                <Link href="/privat">
                                    <div className="button button__info button__info--exposed background--apricot">
                                        <div className="button_header">
                                            Privatperson
                                            <InfoBubble>
                                                Velg dette dersom du selv er part i saken eller registrerer sak for en annen person som er part i saken, for eksempel en forsikringskunde, en klient eller lignende.
                                            </InfoBubble>
                                        </div>
                                        <div className="button__body">
                                            <div>
                                                Velg dette alternativer dersom du registrerer sak for en privatperson eller selv er part i saken.
                                            </div>
                                        </div>
                                    </div>
                                </Link>

                                <Link href="/virksomhet">
                                    <div className="button button__info button__info--exposed background--apricot">
                                        <div className="button_header">
                                            Virksomhet
                                            <InfoBubble>
                                                Velg dette alternativet dersom du registrerer sak for en virksomhet, borettslag eller sameie som er part i saken.
                                            </InfoBubble>
                                        </div>
                                        <div className="button__body">
                                            <div>
                                                Dersom du registrerer sak på vegne av en bedrift, et borettslag eller sameie.
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer/>
        </main>
    );
};

export default Root;
