import React, { useEffect } from "react";
import useForm from "./useForm";
import { IS_SERVER } from "@/helpers/CompileHelper";
import { get } from "lodash/object";

const useTextfieldInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "textfieldInput";
    const elementId = `useTextfieldInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];

    useEffect(() => {
        isValid();
    }, [])

    useEffect(() => {
        if (context != null && props?.key != null) {
            autoExpand(null, true);
        }
    }, [value]);

    const setValue = (value) => {
        forceValue(key, value);
        autoExpand(null, true);
    };

    const isValid = (v = null) => {
        if (IS_SERVER) return;
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;
        let msg = "";

        // TODO

        item?.setCustomValidity?.("");
        return true;
    };

    const handleChangeMiddleman = (e) => {
        autoExpand(e?.target);
        handleChange(e)
        isValid(e?.target?.value);
    };

    const autoExpand = (el, force = false) => {
        if (el == null && force) {
            el = document?.getElementById?.(elementId);
        }

        if (el == null) {
            return;
        }

        // Reset field height
        el.style.height = "inherit";
        el.style.height = (el.scrollHeight + 2) + "px";
    };

    const render = () => {
        return (
            <div>
                { props?.label &&
                    <label htmlFor={elementId}>
                        {props?.label}
                    </label>
                }
                <textarea
                    id={ elementId }
                    name={ key }
                    placeholder={ props?.placeholder }
                    value={ value ?? ""}
                    onChange={ props?.onChange ?? handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    // pattern={ `.{${rule?.minLength ?? 5},${rule?.maxLength}}` }
                    // title={ `Requires between ${rule?.minLength} to ${rule?.maxLength} characters` }
                    minLength={ rule?.minLength ?? rule?.min }
                    maxLength={ rule?.maxLength ?? rule?.max }
                    required={ rule?.required ?? true }
                    className={ props?.class }
                />
                { (rule?.maxLength ?? rule?.max) > 0 &&
                <div>
                    <small>
                        { value?.length ?? 0 } / { (rule?.maxLength ?? rule?.max) }
                    </small>
                </div>
                }

                {/*<div className="input-attachment">*/}
                {/*    { rule?.maxLength > 0 &&*/}
                {/*        <small>*/}
                {/*            { value?.length ?? 0 } / { rule?.maxLength }*/}
                {/*        </small>*/}
                {/*    }*/}
                {/*</div>*/}
            </div>
        );
    };

    return {
        value, render, isValid, setValue,
    };
}

export default useTextfieldInput;
