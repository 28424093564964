import React, { useEffect, useLayoutEffect, useState } from "react";
import useForm from "./useForm";
import { IS_SERVER } from "@/helpers/CompileHelper";
import { get } from "lodash/object";

const useSelectInput = (props) => {
    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const [dynamic, setDynamic] = useState("");
    const rule = props?.rule ?? props?.rules ?? {};

    const key = props?.key ?? "selectInput";
    const elementId = `useSelectInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];
    const optionsBackup = props?.options ?? [];
    const options = props?.optionsFilter ? (() => props?.optionsFilter?.(optionsBackup))() : optionsBackup;
    const containerClass = props?.containerClass ? " " + props?.containerClass : "";

    useEffect(() => {
        isValid();
    }, []);

    useLayoutEffect(() => {
        if (Number(value?.index ?? -1) >= 0) {
            if (dynamic?.length === 0) {
                setDynamic(" select__container--with-value");
            }
        } else {
            setDynamic("");
        }
    }, [value]);

    const setValue = (value, index = null) => {
        if (index !== null) {
            setValue({
                index,
                label: options[index]?.label ?? "",
                id: options[index]?.internal_id ?? options[index]?.id ?? "",
                data: { ...options[index] },
            });
        } else {
            forceValue(key, value);
        }
    };

    const reset = () => {
        forceValue(key, props?.default ?? null);
    };

    const isValid = (v = null) => {
        if (IS_SERVER) return;
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;
        let msg = "";

        if ((rule?.required ?? true) && !(_value?.index >= (rule?.default?.include ? 0 : 1))) {
            item?.setCustomValidity?.("Du må velge et av alternativene.");
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    };

    const handleChangeMiddleman = (e) => {
        props?.onChange?.(options[e.target.value]);
        let index = e.target.value?.length > 0 ? e.target.value : -1;
        setValue({
            index,
            label: options[e.target.value]?.label ?? options[e.target.value]?.name ?? "",
            id: options[e.target.value]?.internal_id ?? options[e.target.value]?.id ?? "",
        });
        isValid({ index: e.target.value });
    };

    const isEqual = (index) => {
        let fallback = index === -1 ? -2 : -1;
        return Number(value?.index ?? fallback) === index;
    };

    const isGreaterThan = (index) => {
        let fallback = index - 1;
        return Number(value?.index ?? fallback) > index;
    };

    const isLessThan = (index) => {
        let fallback = index + 1;
        return index > Number(value?.index ?? fallback);
    };

    const hasOptions = () => {
        return options?.length > 1;
    };

    const render = () => {
        return (
            <div className={ "select__container" + dynamic + containerClass }>
                { (props?.label) &&
                    <label htmlFor={ elementId }>
                        { props?.label }
                    </label>
                }
                <select
                    id={elementId}
                    name={key}
                    placeholder={props?.placeholder ?? ""}
                    value={value?.index ?? ""}
                    onChange={ handleChangeMiddleman }
                    onKeyUp={ props?.onKeyUp }
                    onFocus={ props?.onFocus }
                    required={rule?.required ?? true}
                    className={props?.class}
                >
                    { rule?.default?.include &&
                        <option key={"option"} value="">
                            {rule?.default?.label}
                        </option>
                    }
                    { options?.map?.((item, index) => {
                        return (
                            <option key={"option" + index} value={ rule?.default?.include ? index : index > 0 ? index : ""}>
                                {item?.label ?? item?.name}
                            </option>
                        );
                    })}
                </select>
            </div>
        )
    };

    return {
        value, render, reset, options, isValid, setValue, isEqual, isGreaterThan, isLessThan, hasOptions,
    };
}

export default useSelectInput;
