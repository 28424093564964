import React, { useEffect } from "react";
import useForm from "@/hooks/Form/useForm";
import { get } from "lodash/object";
import { IS_SERVER } from "@/helpers/CompileHelper";
import { useTranslation } from "react-i18next";

const useRadioInput = (props) => {
    const [t] = useTranslation();

    const { values, forceValue, handleChange } = useForm({ key: props?.key, context: props?.context });
    const rule = props?.rule ?? props?.rules ?? {};
    const options = props?.options;

    const key = props?.key ?? "radioInput";
    const elementId = `useRadioInput${key}`;
    const context = props?.context;
    const value = context != null ? get(context?.state, key) : values[key];

    useEffect(() => {
        if (props?.default !== null && value == null) {
            setOption(props?.default);
        } else {
            isValid();
        }
    }, []);

    const isValid = (v = null) => {
        if (IS_SERVER) return true;
        let item = document.getElementById(elementId);
        let _value = v != null ? v : value;

        if ((_value?.index ?? -1) < 0) {
            item?.setCustomValidity?.(t("_errors.input.radio.required"));
            return false;
        }

        item?.setCustomValidity?.("");
        return true;
    }

    const setOption = (index) => {
        isValid({ index: index });
        forceValue(key, {
            index: index,
            ...options[index],
        });
    }

    const option = (item, index) => {
        if (item?.hidden === true || IS_SERVER) return;
        let checked = (value?.index ?? props?.default ?? -1) === index ? " checked" : "";
        let className = props?.buttonClass ?? "";

        return (
            <div
                className={ "button button__toggle " + className + checked }
                key={ index }
                onClick={ () => setOption(index) }
            >
                { item?.label }
            </div>
        );
    }

    const render = () => {
        if (rule?.column) {
            return (
                <div className={ "" + (props?.className ?? props?.class ?? "") }>
                    { options?.map((item, index) => option(item, index)) }
                    <input id={elementId} className="input__faux input__faux--relative"/>
                </div>
            );
        }

        return (
            <div className={ "input__group input__group--small " + (props?.className ?? props?.class ?? "") }>
                { options?.map((item, index) => option(item, index)) }
                <input id={elementId} className="input__faux input__faux--relative"/>
            </div>
        );
    }

    return {
        render, value, setOption,
    }
};

export default useRadioInput;
