import React, { useState } from "react";
import Link from "next/link";
import useApi from "@/synega/hooks/useApi";
import useStringInput from "@/hooks/Form/useStringInput";
import useEmailInput from "@/hooks/Form/useEmailInput";
import usePhoneInput from "@/hooks/Form/usePhoneInput";
import useRadioInput from "@/hooks/Form/useRadioInput";
import useSelectInput from "@/hooks/Form/useSelectInput";
import useTextfieldInput from "@/hooks/Form/useTextfieldInput";
import { INSURANCE_COMPANIES } from "@/data/InsuranceCompanies";
import InfoBubble from "@/components/InfoBubble";
import InfoBox from "@/components/InfoBox";
import { uuidv4 } from "@/helpers/UtilityHelper";
import { IS_DEVELOPMENT } from "@/helpers/CompileHelper";
import { PARTNERS } from "@/data/partners";

const CONTACT_OPTIONS = [
    { label: "Kunden ønsker mer informasjon på e-post" },
    { label: "Kunden ønsker å bli oppringt" },
];

const SIMPLE_OPTIONS = [
    { label: "Ja", value: true },
    { label: "Nei", value: false },
    { label: "Vet ikke", value: false },
];

const SIMPLE_OPTIONS_EXTENDED = [
    { label: "Ja", value: true },
    { label: "Delvis", value: false },
    { label: "Nei", value: false },
    { label: "Vet ikke", value: false },
];

const InfoPage = (props) => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const caseCategories = props?.categories ?? [];

    /* The requester's details. */
    const nameInput = useStringInput({
        label: "Hva er kundens navn?", placeholder: "Ola Nordmann",
    });
    const emailInput = useEmailInput({
        label: "E-post:", placeholder: "ola@post.no",
        rule: { required: false },
    });
    const phoneInput = usePhoneInput();

    /* Additional details regarding the request. */
    const caseCategoryInput = useSelectInput({
        containerClass: "mt-0",
        options: caseCategories, rule: { required: false, default: { include: true, label: "Velg sakstype kategori (valgfritt)" }},
        onChange: (value) => {
            caseTypeInput.setValue(null);
        },
    });
    const caseTypeInput = useSelectInput({
        options: caseCategories, optionsFilter: (options) => {
            let parent = options?.filter(item => {
                if (item.id === caseCategoryInput.value?.id) {
                    return true;
                }
            });

            if (parent.length > 0) {
                return parent[0]?.sub_categories;
            }

            return [];
        }, rule: { required: false, default: { include: true, label: "Velg sakstype underkategori" }},
    });
    const hasBeenInformed = useRadioInput({
        key: "has-been-informed", class: "input__group--grid mb-4",
        options: SIMPLE_OPTIONS_EXTENDED, buttonClass: " background--apricot",
    });

    const infoInput = useTextfieldInput({
        placeholder: "Noe ekstra vi bør vite? (Valgfritt)",
        rule: { max: 500, required: false },
    });
    const partnerInput = useSelectInput({
        label: "Velg samarbeidspartner",
        rule: { default: { include: true, label: "Velg ett av alternativene" }},
        options: PARTNERS, onChange: (value) => {
            try {
                if (insuranceInput.isValid()) return;

                let index = insuranceInput.options.findIndex(s => s.label.includes(value.label));
                if (index >= 0) {
                    insuranceInput.setValue(null, index);
                }
            } catch {/* Ignore any errors */}
        },
    });
    // const contactOptions = useRadioInput({
    //     className: "input__group--mt-0 input__radio--fit", key: "contact-option",
    //     options: CONTACT_OPTIONS, buttonClass: " background--apricot",
    // });

    /* Details regarding the requester's insurance. */
    const hasInsurance = useRadioInput({
        key: "has-insurance",
        options: SIMPLE_OPTIONS, buttonClass: " background--apricot",
    });
    const insuranceInput = useSelectInput({
        options: INSURANCE_COMPANIES,
    });
    const insuranceNumber = useStringInput({
        label: "Saksnummer:", rule: { required: false },
    });

    /* Details regarding the requester's counterpart. */
    const hasCounterpartDetails = useRadioInput({
        key: "has-counterpart-details",
        options: SIMPLE_OPTIONS, buttonClass: " background--apricot",
    });
    const counterNameInput = useStringInput({
        key: "counterpart-name",
        label: "Navn:", placeholder: "Ola Nordmann",
    });
    const counterEmailInput = useEmailInput({
        key: "counterpart-email", rule: { required: false },
        label: "E-post:", placeholder: "ola@post.no",
    });
    const counterPhoneInput = usePhoneInput({
        key: "counterpart-phone", rule: { required: false },
    });

    const submit = (e) => {
        e.preventDefault();

        let note = "Utfylt via info.mekle.no"; // contactOptions.value.label;

        if (caseCategoryInput.value?.index >= 0) {
            note += `. Sakskategorien er "${caseCategoryInput.value?.label ?? "???"}"`;
            if (caseTypeInput.value?.index >= 0) {
                note += ` og underkategorien er "${caseTypeInput.value?.label ?? "???"}"`;
            } else {
                note += ", men det er ikke spesifisert noe underkategori";
            }
        } else {
            note += ". Det er ikke spesifisert noe sakskategori";
        }

        if (infoInput.value?.trim()?.length > 0) {
            let temptext = infoInput.value.trim().replace(/\s+/g, " ");
            if (temptext[temptext.length - 1] === ".") temptext = temptext.substring(0, temptext.length - 1);
            note += `. Litt om hva det gjelder: "${temptext}"`;
        } else {
            note += ". Det er ikke gitt noe ekstra informasjon om hva det gjelder"
        }

        note += `. ${hasInsurance.value.index !== 2 ? "Kunden har " : "Utfyller "}${hasInsurance.value.value ? "" : hasInsurance.value.index !== 2 ? "ikke" : "vet ikke om kunden har"} rettshjelpsforsikring.`
        if (hasInsurance.value.value) {
            note += ` Forsikringsselskapet er "${insuranceInput.value.label}", `
            if (insuranceNumber.value?.length > 0) {
                note += `saksnummeret er "${insuranceNumber.value}".`
            } else {
                note += "men det er ikke gitt noe saksnummer."
            }
        }

        if (!hasCounterpartDetails.value.value) {
            note += ` Utfyller ${hasCounterpartDetails.value.index !== 2 ? "har " : ""}${hasCounterpartDetails.value.index !== 2 ? "ikke" : "vet ikke hva"} kontaktinfo til motpart${hasCounterpartDetails.value.index !== 2 ? "" : "en er"}.`;
        } else {
            note += ` Navn på motparten er "${counterNameInput.value}",`
            if (counterEmailInput.isValid()) {
                note += ` epost adressen er "${counterEmailInput.value}",`
            } else {
                note += " utfyller har ikke epost adressen deres,"
            }
            if (counterPhoneInput.isValid()) {
                note += ` telefonnummeret er "${counterPhoneInput.e164()}",`
            } else {
                note += " utfyller har ikke telefonnummeret deres."
            }
        }

        note += ` Samarbeidspartner er "${partnerInput.value.label}". "${hasBeenInformed.value?.label}" til spørsmålet "er kunden informert om mekling og ønsker å mekle".`;

        let data = {
            name: nameInput.value,
            phone: phoneInput.value,
            email: emailInput.value,
            client_type: "client",
            form: "partner",
            case: {
                notes: note.trim(),
                is_prospect: hasBeenInformed.value?.value ?? false,
                category: caseCategoryInput.value,
                subcategory: caseTypeInput.value,
            },
            counterpart: {
                has: hasCounterpartDetails.value.value,
                name: counterNameInput,
                phone: { ...counterPhoneInput, valid: counterPhoneInput.isValid() },
                email: { ...counterEmailInput, valid: counterEmailInput.isValid() },
            },
            insurance: {
                has: hasInsurance.value.value,
                ...insuranceInput.value,
                reference: insuranceNumber.value,
            },
            partner: {
                company: {
                    ...partnerInput.value,
                },
            },
        }

        const is_debug = IS_DEVELOPMENT && true;
        if (is_debug) {
            // data.name = `Gisle Test ${uuidv4().substring(0, 5)}`;
            // data.email = `test${uuidv4().substring(0, 5)}@virtualvikings.io`;
        }

        setLoading(true);
        api.post("/leads", data).then(res => {
            if (res?.status === "success") {
                setTimeout(() => {
                    if (!is_debug) setUploaded(true);
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false)
        });
    };

    const insuranceLabel = () => {
        return (
            <InfoBox label="Har du rettshjelpforsikring?">
                <p>
                    Har du en forsikring som dekker skade på hus, hytte, innbo, bil og båt har du som regel krav på å få dekket kostnader til mekling hos oss helt gratis og uten egenandel.
                </p>
                <p>
                    Du kan melde saken din direkte ved å trykke på lenken til forsikringsselskapene vi har samarbeid med nedenfor eller vi kan undersøke dekning for deg. Forsikringsselskapet dekker også kostnaden til den andre parten.
                </p>
                <ul>
                    <li>
                        <a href="https://www.gjensidige.no/privat/meld-skade/rettshjelp" target="_blank" rel="noopener noreferrer">
                            Gjensidige
                        </a>
                    </li>
                    <li>
                        <a href="https://www.storebrand.no/privat/forsikring/meld-skade/rettshjelp" target="_blank" rel="noopener noreferrer">
                            Storebrand
                        </a>
                    </li>
                    <li>
                        <a href="https://www.frende.no/aktuelt/aldri-alene-i-tvist/" target="_blank" rel="noopener noreferrer">
                            Frende Forsikring
                        </a>
                    </li>
                </ul>
                <p>
                    Det er noen tvister som forsikringen ikke dekker. Dette gjelder straffesaker, tvister omkring arbeid/yrke, samlivsbrudd, arve- og familierettslige tvister.
                </p>
                <p>
                    Har du spørsmål? Send e-post til post@mekle.no eller ring <span className="nobreak">22 44 41 00</span>
                </p>
            </InfoBox>
        );
    };

    /* Vi har registrert henvendelsen, vi kommer til å ta kontakt så snart som mulig! */
    if (uploaded) {
        return (
            <section className="section--cover">
                <div className="container container--valign center mb-8">
                    <div className="row justify-content-center mb-4">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                            <h1 className="h3m-md-up h2m-md-down">
                                Takk, du vil høre fra oss om kort tid
                            </h1>
                        </div>
                    </div>

                    <div className="row justify-content-center mb-8">
                        <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                            <Link href="https://mekle.no/">
                                <div className={ "button background--black" }>
                                    Returner til hovedsiden
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    return (
        <section className="section--cover">
            <form className="container" onSubmit={submit}>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6">
                        <h1 className="h3m-lg-up h2m-md-down center">
                            Fyll ut skjema på vegne av deres kunde, så vil MEKLE håndtere saken videre
                        </h1>
                    </div>
                </div>

                <div className="row justify-content-center mb-sm-1">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6">
                        {nameInput.render()}
                        <div className="input__group input__group--mb-0">
                            {emailInput.render()}
                            {phoneInput.render()}
                        </div>
                        <p className="mt-1">
                            Om saken
                        </p>
                        {caseCategoryInput.render()}
                        {caseCategoryInput.isValid() && caseTypeInput.hasOptions() && caseTypeInput.render()}
                        {infoInput.render()}
                        {partnerInput.render()}

                        <p className="mt-4">
                            Er kunden informert om mekling og ønsker å mekle?
                        </p>
                        {hasBeenInformed.render()}

                        <p className="mt-5">
                            Har kunden rettshjelpforsikring?
                        </p>
                        {hasInsurance.render()}
                        { hasInsurance.value?.value &&
                        <>
                            <div style={{ display: "flex", flexDirection: "row", position: "relative" }}>
                                <p>
                                    Velg forsikringsselskap
                                </p>
                                <InfoBubble bubble={{ className: "info__bubble--transparent" }}>
                                    Legg ved saksnummer dersom du har ett. Dersom ikke, la feltet stå tomt.
                                </InfoBubble>
                            </div>
                            <div className="input__group input__group--my-0">
                                {insuranceInput.render()}
                                {insuranceNumber.render()}
                            </div>
                        </>
                        }

                        <p className="mt-5">
                            Har du kontaktinfo til motpart?
                        </p>
                        {hasCounterpartDetails.render()}
                        { hasCounterpartDetails.value?.value &&
                        <>
                            {counterNameInput.render()}
                            <div className="input__group input__group--mb-0">
                                {counterEmailInput.render()}
                                {counterPhoneInput.render()}
                            </div>
                            <small>
                                Det går fint om du kun har navnet deres.
                            </small>
                        </>
                        }
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                        <button className={ "button background--black" + (loading ? " hide-mobile-up" : "") }>
                            Send
                        </button>
                        <div className={ "loader__container loader__container--large mt-6" + (loading ? " show" : "") }>
                            <div className="loader--swoosh"/>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default InfoPage;
