import React, { useState } from "react";
import useApi from "@/synega/hooks/useApi";
import useStringInput from "@/hooks/Form/useStringInput";
import useEmailInput from "@/hooks/Form/useEmailInput";
import usePhoneInput from "@/hooks/Form/usePhoneInput";
import ContactPageUploaded from "@/components/contact-page/uploaded";
import useTextfieldInput from "@/hooks/Form/useTextfieldInput";
import { IS_DEVELOPMENT, IS_PRODUCTION } from "@/helpers/CompileHelper";
import { reportConversion } from "@/helpers/google/tag";

const ContactPage = (props) => {
    const api = useApi();
    const [loading, setLoading] = useState(false);
    const [uploaded, setUploaded] = useState(false);

    /* The requester's details. */
    const nameInput = useStringInput({
        label: "Ditt navn:", placeholder: "Ola Nordmann",
    });
    const emailInput = useEmailInput({
        label: "Din e-post:", placeholder: "ola@post.no",
    });
    const phoneInput = usePhoneInput();

    /* Additional details regarding the request. */
    const infoInput = useTextfieldInput({
        placeholder: "Noe ekstra vi bør vite? (Valgfritt)",
        rule: { max: 500, required: false },
    });

    const submit = (e) => {
        e.preventDefault();

        let note = "Utfylt via kontakt.mekle.no";
        if (phoneInput.value?.number?.length > 0) {
            note += `. Telefon (+${phoneInput.value?.dial_code}.${phoneInput.value?.number})`
        }

        if (emailInput.value?.length > 0) {
            if (phoneInput.value?.number?.length > 0) {
                note += " og"
            }

            note += ` Epost (${emailInput.value})`
        }

        if (infoInput.value?.trim()?.length > 0) {
            let temptext = infoInput.value.trim().replace(/\s+/g, " ");
            if (temptext[temptext.length - 1] === ".") temptext = temptext.substring(0, temptext.length - 1);
            note += `. Litt om hva det gjelder: "${temptext}"`;
        } else {
            note += ". Jeg har ikke gitt noe ekstra informasjon om hva det gjelder";
        }

        note += ".";
        let data = {
            name: nameInput.value,
            phone: phoneInput.value,
            email: emailInput.value,
            form: "contact",
            case: {
                notes: note.trim(),
            },
        }

        const is_debug = IS_DEVELOPMENT && true;
        if (is_debug) {
            // data.name = `Gisle Test ${uuidv4().substring(0, 5)}`;
            // data.email = `test${uuidv4().substring(0, 5)}@virtualvikings.io`;
        }

        setLoading(true);
        api.post("/leads", data).then(res => {
            if (res?.status === "success") {
                if (IS_PRODUCTION) {
                    reportConversion("O5XwCKiip4oYEJ749MEo");
                }

                setTimeout(() => {
                    if (!is_debug) setUploaded(true);
                    setLoading(false);
                }, 1000);
            } else {
                setLoading(false);
            }
        }).catch(() => {
            setLoading(false)
        });
    };

    /* Vi har registrert henvendelsen, vi kommer til å ta kontakt så snart som mulig! */
    if (uploaded) {
        return <ContactPageUploaded/>
    }

    return (
        <section className="section--cover">
            <form className="container" onSubmit={submit}>
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6">
                        <h1 className="h3m-lg-up h2m-md-down center">
                            Fyll ut skjema nedenfor og du vil høre fra oss raskt
                        </h1>
                    </div>
                </div>

                <div className="row justify-content-center mb-sm-1">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6">
                        {nameInput.render()}
                        <div className="input__group input__group--mb-0">
                            {emailInput.render()}
                            {phoneInput.render()}
                        </div>
                        {infoInput.render()}
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                        <button className={ "button background--black" + (loading ? " hide-mobile-up" : "") }>
                            Send
                        </button>
                        <div className={ "loader__container loader__container--large mt-6" + (loading ? " show" : "") }>
                            <div className="loader--swoosh"/>
                        </div>
                    </div>
                </div>
            </form>
        </section>
    );
};

export default ContactPage;
