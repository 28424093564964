import React from "react";
import Link from "next/link";

const ContactPageUploaded = (props) => {
    return (
        <section className="section--cover">
            <div className="container container--valign center mb-8">
                <div className="row justify-content-center mb-4">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                        <h1 className="h3m-md-up h2m-md-down">
                            Takk, du vil høre fra oss om kort tid
                        </h1>
                    </div>
                </div>

                <div className="row justify-content-center mb-8">
                    <div className="col-12 col-sm-11 col-md-9 col-lg-7 col-hu-6 center">
                        <Link href="https://mekle.no/">
                            <div className={ "button background--black" }>
                                Returner til hovedsiden
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ContactPageUploaded;
