export const PARTNERS = [
    {
        label: "Gjensidige",
        // id: "",
    },
    {
        label: "Storebrand",
        // id: "",
    },
    {
        label: "Frende",
        // id: "",
    },
    {
        label: "Kravia",
        id: "Kravia",
    },
    {
        label: "Tryg Forsikring",
        // id: "",
    },
    // { // "Slettet" 21.02.2024
    //     label: "Mitt Anbud",
    //     id: "Mitt Anbud",
    // },
    {
        label: "Finn.no",
        id: "Finn.no",
    },
    // { // "Slettet" 21.02.2024
    //     label: "Finans Norge",
    //     // id: "",
    // },
    // { // "Slettet" 21.02.2024
    //     label: "Eiendomsfinans",
    //     // id: "",
    // },
    {
        label: "KLP",
        // id: "",
    },
    {
        label: "Swiftcourt",
        id: "Swiftcourt",
    },
    {
        label: "Watercircles",
        id: "Watercircles",
    },
    {
        label: "Ikke på listen",
        id: "Ikke på listen",
    },
];
